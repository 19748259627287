import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { blue } from '@mui/material/colors';
import { Card, TextField, CardHeader, DialogActions, DialogContent, Grid, Stack } from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { ToastAlert } from '../../components/MyAlert';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { getSystemSettings, saveSystemSettings } from '../../components/MyUtil';

export function DialogSystemSettings(props) {
  const { open, handleDialogClose } = props;
  const [oldData, setOldData] = React.useState();
  const [pageSize, setPageSize] = React.useState(20);

  const [isProcessing, setIsProcessing] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  React.useEffect(() => {
    //每次更新页面后会执行，且仅执行一次
    if (open) {
      var systemSettings = getSystemSettings();
      if(!systemSettings){
        systemSettings = { pageSize: 20};
      }
      setOldData(systemSettings);
      setPageSize(systemSettings.pageSize);
    }
  }, [props]);


  const handleClose = () => {
    let savedSuccess = false;
    let data = oldData;
    handleDialogClose({ savedSuccess, data });
  };

  const handleSave = () => {
    let savedSuccess = true;

    let data = {};
    Object.assign(data, oldData);
    data.pageSize = pageSize;
    saveSystemSettings(data);
    handleDialogClose({ savedSuccess, data });
    ToastAlert.fire({
      icon: 'success',
      html: `${t('COMMON.MSG_SAVE') + t('COMMON.MSG_SUFFIX_SUCCESS')}`
    });

  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open={open}
      maxWidth='sm'
      fullScreen={fullScreen}
      scroll='body'
    >
      <CardHeader
        title={
          <Typography variant="h5" sx={{ mb: 1 }}>{t('SYSTEM.SETTINGS')}</Typography>
        }
        avatar={
          <Avatar sx={{ bgcolor: blue[500], width: 50, height: 50 }} aria-label="recipe">
            S
          </Avatar>
        }
      />

      <DialogContent sx={{ pt: 2, pb: 1 }}>
        <Grid container spacing={2} paddingX={0} paddingTop={1} paddingBottom={0}>
          <Grid item xs={12} md={12}>
            <Stack direction="column" spacing={3} padding={3} sx={{ pt: 1 }}>

              <TextField
                required
                fullWidth
                type="number"
                label={t('SYSTEM.PAGE_SIZE')}
                id="page_size"
                disabled={isProcessing}
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value.trim())}
                helperText={t('SYSTEM.PAGE_SIZE_HELPER')}
              />


            </Stack>
          </Grid>
 
        </Grid>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" alignItems="right" spacing={2} paddingBottom={1} paddingRight={3}>
          <Button variant="outlined" onClick={handleClose} disabled={isProcessing} startIcon={<Close />}>{t('COMMON.BUTTON_CANCEL')}</Button>
          {/* <Button variant="contained" onClick={handleSave} startIcon={<Check />} autoFocus>存儲</Button> */}
          <LoadingButton color="primary" variant="contained" type="submit" size="small" startIcon={<Save /> } loadingPosition="start"
            onClick={handleSave} 
            loading={isProcessing} >{t('COMMON.BUTTON_SAVE')}</LoadingButton>
        </Stack>
      </DialogActions>

    </Dialog>
  );
};

DialogSystemSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  // savedSuccess: PropTypes.bool.isRequired,
};
