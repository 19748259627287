import cookie from 'react-cookies'
import { jwtDecode } from "jwt-decode";
import { g } from '../Config';

export function getGroupIdFromJwtToken() {
    let jwtPayload = getJwtPayload();
    // console.log(JSON.stringify(jwtPayload, null, 2));
    return jwtPayload ? Number(jwtPayload.role) : -1;
}
export function getTokenCookiePath() {
    return global.constants.appName + '_' + g.clientId + '_TOKEN';
};
export function getJwtPayload() {
    let access_token = cookie.load(getTokenCookiePath());
    if (!access_token) {
        return null;
    } else {
        return jwtDecode(access_token);
    }
};
export function getJwtToken() {
    return cookie.load(getTokenCookiePath());
};
export function saveJwtToken(data) {
    let payload = jwtDecode(data);
    // const expires_in = payload.exp - new Date(Date.now().valueOf()) / 1000;
    cookie.save(
        getTokenCookiePath(),
        data,
        {
            path: '/',
            // expires: expires,
            // maxAge: payload.exp - new Date(Date.now().valueOf()) / 1000,  //如果不設定maxAge，那么在关闭浏览器之后 cookie 就会失效
            sameSite: 'lax',
        }
    );
};
export function removeJwtToken() {
    cookie.remove(getTokenCookiePath(), { path: '/' });
};
export function getSystemSettings() {
    return cookie.load(global.constants.appName + '_' + g.clientId + '_SystemSettings');
};

export function saveSystemSettings(data) {
    cookie.save(
        global.constants.appName + '_' + g.clientId + '_SystemSettings',
        data,
        {
            path: '/',
            // expires: expires,
            maxAge: 60 * 60 * 24 * 365 * 100,// expires_in,
            sameSite: 'lax',
        }
    );
};

export function getParentUserId(userId) {
    let ary = userId.split("_");
    return ary[0];
}

export function replaceIllegalFilenameChars(filename){
    return filename.replace(/^\.|[\\\\/:*?\"<>|]/g, '-');
}