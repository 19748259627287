export const g =  window.g;

global.constants = {
    // tokenName: 'SANDLAPORTAL_TOKEN_',
    appName: 'SANDLAPORTAL',
    // vk:'shylu',
    // defaultLang: 'id',
    // baseApiUrl:'https://www.sand-la.com/SandLaPortalApi',
    // baseApiUrl:'http://localhost:5089',
};

// const config = {
//     // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
//     // like '/berry-material-react/react/default'
//     basename: '',
//     defaultPath: '/',
//     fontFamily: `'Roboto', sans-serif`,
//     borderRadius: 12
// };

// export default config;


