import './App.css';
import { useSelector } from 'react-redux';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';


/*
const theme = createTheme({
  typography: {
    //  fontFamily: [
    //     'Helvetica Neue',
    //     'Open Sans',
    //     '-apple-system',
    //     'BlinkMacSystemFont',
    //     '"Segoe UI"',
    //     'Roboto',
    //     '"Helvetica Neue"',
    //     'Arial',
    //     'sans-serif',
    //     '"Apple Color Emoji"',
    //     '"Segoe UI Emoji"',
    //     '"Segoe UI Symbol"',
    //   ].join(','),
    fontSize: 13
  },
  palette: {
    primary: {
      // main: '#1976d2',
      // main: '#4caf50',
      // main: '#8bc34a',
      // main: '#2196f3',
      main: '#42a5f5',
      // main: 'rgb(0, 171, 85)',
      contrastText: '#fff',
      background: '#f5f5f5',
    },
    // secondary: {
    //   main: '#edf2ff', 
    // },
  },


  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      // styleOverrides: {
      //   // Name of the slot
      //   root: {
      //     // Some CSS
      //     // fontFamily: `"sans-serif","Arial", "Helvetica", "Roboto"`,
      //     fontSize: 13,
      //   },
      // },
    },
    MuiSelect:{
      defaultProps: {
        // size: 'small',
      },
        styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontFamily: `"sans-serif","Arial", "Helvetica", "Roboto"`,
          // fontSize: 15,
        },
      },

    },
    MuiTextField: {
      defaultProps: {
        // margin: 'dense',
        // size: 'small',
      },
    },
    MuiRadio:{
      defaultProps: {
        // size: 'small',
      },
    
    }
    //   MuiButton: {
    //     defaultProps: {
    //       size: 'small',
    //     },
    //   },
    //   MuiFilledInput: {
    //     defaultProps: {
    //       margin: 'dense',
    //     },
    //   },
    //   MuiFormControl: {
    //     defaultProps: {
    //       margin: 'dense',
    //     },
    //   },
    //   MuiFormHelperText: {
    //     defaultProps: {
    //       margin: 'dense',
    //     },
    //   },
    //   MuiIconButton: {
    //     defaultProps: {
    //       size: 'small',
    //     },
    //   },
    //   MuiInputBase: {
    //     defaultProps: {
    //       margin: 'dense',
    //     },
    //   },
    //   MuiInputLabel: {
    //     defaultProps: {
    //       margin: 'dense',
    //     },
    //   },
    //   MuiListItem: {
    //     defaultProps: {
    //       dense: true,
    //     },
    //   },
    //   MuiOutlinedInput: {
    //     defaultProps: {
    //       margin: 'dense',
    //     },
    //   },
    //   MuiFab: {
    //     defaultProps: {
    //       size: 'small',
    //     },
    //   },
    //   MuiTable: {
    //     defaultProps: {
    //       size: 'small',
    //     },
    //   },
    //   MuiTextField: {
    //     defaultProps: {
    //       margin: 'dense',
    //     },
    //   },
    //   MuiToolbar: {
    //     defaultProps: {
    //       variant: 'dense',
    //     },
    //   },
  },
});
*/

function App() {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      {/* <ThemeProvider theme={theme}> */}
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
          {/* <TaskList></TaskList> */}
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
    // <SamplePage></SamplePage>
      // <MainLayout></MainLayout>
      // <TaskList></TaskList>
  );

}

export default App;
