import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import resources from "./resources";
import Backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

// const resources = {
//   en: {
//     translation: {
//       "LOGIN": "Login22"
//     }
//   },
//   cn: {
//     translation: {
//       "LOGIN": "登入"
//     }
//   }
// };

i18n
  .use(Backend)
  .use(LanguageDetector) //偵測瀏覽器語言
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    // resources,
    backend: {
      loadPath: "./locales/{{lng}}/{{ns}}.json"
    },
    load: 'languageOnly',
    fallbackLng: "en",
    // lng: "zh-CN", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;