// import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2"
import { removeJwtToken } from "./MyUtil";


export function goRelogin(t, invoker, showExpiredAlert = true) {
  // const { t } = useTranslation();

  // cookie.remove(getTokenCookiePath(), { path: '/' });
  // alert('goRelogin() invoker=' + invoker);
  removeJwtToken();

  if (showExpiredAlert) {
    let waitSeconds = 10;
    Swal.fire({
      title: t('LOGIN.TOKEN_EXPIRED'),
      html: waitSeconds + t('LOGIN.GO_LOGIN_PAGE_AFTER_SECONDS'),
      confirmButtonText: t('LOGIN.LOGIN_NOW'),
      timer: waitSeconds * 1000,
      timerProgressBar: true,
    }).then((result) => {
      /* Read more about handling dismissals below */
      // if (result.dismiss === Swal.DismissReason.timer) {
      //   console.log('I was closed by the timer');
      // }
      window.location.href = "./SignIn";
    });
  } else {
    window.location.href = "./SignIn";
  }
};

export function showHttpError(t, error, actionName, invoker = '') {
  if (error.response) {
    // alert(error.response.status);
    switch (error.response.status) {
      case 401:
        goRelogin(t, invoker + ' ==> MyAlert.showHttpError() Http 401');
        break;
      case 403:
        Swal.fire(`${actionName}${t('COMMON.MSG_SUFFIX_FAIL')}`, t('LOGIN.MSG_HTTP_403'), "error")
        // Swal.fire(`Forbidden`, `Access Forbidden`, "error")
        break;
      default:
        Swal.fire(`${actionName}${t('COMMON.MSG_SUFFIX_FAIL')}`, `Network Error: ${JSON.stringify(error, null, 2)}`, "error")
        break;
    }
  } else {
    Swal.fire(`${actionName}${t('COMMON.MSG_SUFFIX_FAIL')}`, `Network Error: ${JSON.stringify(error, null, 2)}`, "error")
  }

};

export function showApiError(t, failed_title, response_data) {
  switch (response_data.code) {
    case 1007: //Unauthorized Access
      Swal.fire(failed_title, t('COMMON.MSG_UNAUTHORIZED_ACCESS'), 'warning');
      goRelogin(t, 'MyAlert.showApiError()', false);
      break;
    case 1008: //Login Fail
      Swal.fire(failed_title, t('COMMON.MSG_LOGIN_FAIL'), 'warning');
      goRelogin(t, 'MyAlert.showApiError()', false);
      break;
    case 9999: //System Error
      Swal.fire(failed_title, t('COMMON.MSG_SYSTEM_ERROR') + ": " + response_data.message, 'error');
      break;
    default:
      Swal.fire(failed_title, t('COMMON.MSG_ERROR') + ": " + response_data.code + "|" + response_data.message, 'error');
      break;
  }

};


// const ToastAlert = ()=>{
//     return Swal.mixin({
//         toast: true,
//         position: 'top-end',
//         showConfirmButton: false,
//         timer: 3000,
//         // timerProgressBar: true,
//         didOpen: (toast) => {
//             toast.addEventListener('mouseenter', Swal.stopTimer)
//             toast.addEventListener('mouseleave', Swal.resumeTimer)
//         }
//     });
// };
// export {ToastAlert};
// export default ToastAlert();

const ToastAlert = (
  Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    // timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
);

export { ToastAlert };

