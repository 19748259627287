// import '../App.css';
import './Sidebar.css';
import * as React from 'react';
import { Box, Drawer, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Key, Paid, Assignment, Group, ManageSearch, Public, Groups, ContactPhone, HistoryEdu, Summarize } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { getJwtPayload } from '../../../components/MyUtil';

// import { useDispatch, useSelector } from 'react-redux';
// import { MENU_ITEM_SELECTED } from '../../../store/actions';

export default function Sidebar(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = React.useState("");
    const [groupId, setGroupId] = React.useState(-1);
    const [level, setLevel] = React.useState(99);
    // const handleMenuItemClick = (idx) => {
    //     dispatch({ type: MENU_ITEM_SELECTED, selectedItemIndex: idx });
    //     console.log('menu item clicked. selectedItemIndex=' + selectedItemIndex);
    // };
    const getItemColor = (idx) => {
        if (selectedItem === idx) {
            return '#ef6c00';
            // return '#1976d2';
        } else {
            return '';
        }
    }

    const getIconColor = (idx) => {
        if (selectedItem === idx) {
            return '#ef6c00';
            // return '#1976d2';
        } else {
            // return 'green[500]';
            return '#4caf50';
        }

    }

    const navItemBorderRadius = 1;
    const navItemMarginLeft = 1;
    const isDense = true;
    // const dispatch = useDispatch();
    // const selectedItemIndex = useSelector((state) => state.customization.selectedItemIndex);
    // console.log('selectedItemIndex=' + selectedItemIndex);

    const location = useLocation();
    React.useEffect(() => {
        // console.log('当前路由URL: ' + location.pathname);
        var jwtPayload = getJwtPayload();
        setGroupId(jwtPayload ? Number(jwtPayload.role) : -1);
        setLevel(jwtPayload ? Number(jwtPayload.level) : 99);
        let pathname = location.pathname.toLowerCase();
        switch (pathname) {
            // case "/":
            case "/tasknew":
                pathname = "/tasklist";
                break;
            case "/accountnew":
                pathname = "/accountlist";
                break;
            case "/userpricenew":
                pathname = "/userpricelist";
                break;
            default:
                break;
        }
        setSelectedItem(pathname);

        /*
        switch (location.pathname.toLowerCase()) {
            //发送任务
            case "/tasklist":
            case "/tasknew":
                setSelectedIndex(11);
                break;
            case "/mtlog":
                setSelectedIndex(12);
                break;
            case "/reporting":
                setSelectedIndex(13);
                break;

            //通訊錄 & 簽名檔
            case "/addressgroup":
                setSelectedIndex(21);
                break;
            case "/contacts":
                setSelectedIndex(22);
                break;
            case "/signature":
                setSelectedIndex(23);
                break;

            //子帳號設定
            case "/subaccounts":
                setSelectedIndex(31);
                break;
            case "/topup":
                setSelectedIndex(32);
                break;
            case "/topuplog":
                setSelectedIndex(33);
                break;

            //我的賬號
            case "/authorizedcountries":
                setSelectedIndex(41);
                break;
            case "/changepwd":
                setSelectedIndex(42);
                break;
            default:
                break;
        }
        */
    }, [location]);


    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={props.open}
            sx={{
                // letterSpacing: '0.03333em',
                width: props.drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: props.drawerWidth, boxSizing: 'border-box' },
            }}
        // sx={{
        //   // display: { xs: 'block', lg: 'none' },
        //   // width: drawerWidth,
        //   '& .MuiDrawer-paper': {
        //       boxSizing: 'border-box',
        //       width: drawerWidth,
        //       flexShrink: 0,
        //       // borderRight: `1px solid ${theme.palette.divider}`,
        //       backgroundImage: 'none',
        //       boxShadow: 'inherit'
        //   }
        // }}
        >
            {/* <Toolbar /> */}

            <Box sx={{ overflow: 'auto', padding: 1, mt: props.topbarHeight + 'px' }}>
                <List
                    dense={isDense}
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={<ListSubheader>{t('SIDEBAR.MT_TASK')}</ListSubheader>}
                >
                    {/* <Link to="/tasklist"> */}
                    <ListItemButton
                        selected={selectedItem === "/tasklist"}
                        // onClick={() => handleMenuItemClick(11)}
                        sx={{ color: getItemColor("/tasklist"), borderRadius: navItemBorderRadius }}
                        onClick={() => { navigate("/tasklist") }}
                    >
                        <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                            <Assignment sx={{ color: getIconColor("/tasklist") }} />
                        </ListItemIcon>
                        <ListItemText primary={t('SIDEBAR.MY_TASKS')} />
                    </ListItemButton>
                    {/* </Link> */}

                    {/* <Link to="/sentlog"> */}
                    <ListItemButton
                        selected={selectedItem === "/sentlog"}
                        // onClick={(event) => handleMenuItemClick(12)}
                        sx={{ color: getItemColor("/sentlog"), borderRadius: navItemBorderRadius }}
                        onClick={() => { navigate("/sentlog") }}
                    >
                        <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                            {/* <ManageSearch color="primary" /> */}
                            <ManageSearch sx={{ color: getIconColor("/sentlog") }} />
                        </ListItemIcon>
                        <ListItemText primary={t('SIDEBAR.SENT_LOG')} />
                    </ListItemButton>
                    {/* </Link> */}

                    {/* <Link to="/reports"> */}
                    <ListItemButton
                        selected={selectedItem === "/reports"}
                        // onClick={(event) => handleMenuItemClick(13)}
                        sx={{ color: getItemColor("/reports"), borderRadius: navItemBorderRadius }}
                        onClick={() => { navigate("/reports") }}
                    >
                        <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                            <Summarize sx={{ color: getIconColor("/reports") }} />
                        </ListItemIcon>
                        <ListItemText primary={t('SIDEBAR.REPORTING')} />
                    </ListItemButton>
                    {/* </Link> */}
                </List>
                {/* <Divider light /> */}
                {groupId < 50 && <>
                    <hr width="95%" boder="0" size="1" align="left" color="#ececec" />

                    <List
                        dense={isDense}
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>{t('SIDEBAR.SUSPICIOUS_MESSAGE')}</ListSubheader>}
                    >
                        <ListItemButton
                            selected={selectedItem === "/suspiciousmt"}
                            // onClick={(event) => handleMenuItemClick(21)}
                            sx={{ color: getItemColor("/suspiciousmt"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/suspiciousmt") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <Groups sx={{ color: getIconColor("/suspiciousmt") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.SUSPICIOUS_MESSAGES')} />
                        </ListItemButton>

                        <ListItemButton
                            selected={selectedItem === "/sensitivewords"}
                            // onClick={(event) => handleMenuItemClick(22)}
                            sx={{ color: getItemColor("/sensitivewords"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/sensitivewords") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <ContactPhone sx={{ color: getIconColor("/sensitivewords") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.SENSITIVE_WORDS')} />
                        </ListItemButton>

                        <ListItemButton
                            selected={selectedItem === "/whitelistwords"}
                            // onClick={(event) => handleMenuItemClick(23)}
                            sx={{ color: getItemColor("/whitelistwords"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/whitelistwords") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <HistoryEdu sx={{ color: getIconColor("/whitelistwords") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.WHITELIST_WORDS')} />
                        </ListItemButton>
                    </List>
                </>}


                {groupId === 100 && <>
                    <hr width="95%" boder="0" size="1" align="left" color="#ececec" />

                    <List
                        dense={isDense}
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>{t('SIDEBAR.CONTACT_SIGNATURE')}</ListSubheader>}
                    >
                        {/* <Link to="/addressgrouplist"> */}
                        <ListItemButton
                            selected={selectedItem === "/addressgrouplist"}
                            // onClick={(event) => handleMenuItemClick(21)}
                            sx={{ color: getItemColor("/addressgrouplist"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/addressgrouplist") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <Groups sx={{ color: getIconColor("/addressgrouplist") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.CONTACT_GROUPS')} />
                        </ListItemButton>
                        {/* </Link> */}

                        {/* <Link to="/addressbooklist"> */}
                        <ListItemButton
                            selected={selectedItem === "/addressbooklist"}
                            // onClick={(event) => handleMenuItemClick(22)}
                            sx={{ color: getItemColor("/addressbooklist"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/addressbooklist") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <ContactPhone sx={{ color: getIconColor("/addressbooklist") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.CONTACTS')} />
                        </ListItemButton>
                        {/* </Link> */}

                        {/* <Link to="/signaturelist"> */}
                        <ListItemButton
                            selected={selectedItem === "/signaturelist"}
                            // onClick={(event) => handleMenuItemClick(23)}
                            sx={{ color: getItemColor("/signaturelist"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/signaturelist") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <HistoryEdu sx={{ color: getIconColor("/signaturelist") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.SIGNATURES')} />
                        </ListItemButton>
                        {/* </Link> */}
                    </List>
                </>}

                {/* <Divider light /> */}
                <hr width="95%" boder="0" size="1" align="left" color="#ececec" />

                <List
                    dense={isDense}
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={<ListSubheader>{t('SIDEBAR.SUB_ACCOUNT')}</ListSubheader>}
                >
                    {((groupId === 0 || groupId === 1 || groupId === 10 || groupId === 11) || (groupId === 100 && level === 1)) &&
                        // <Link to="/accountlist">
                        <ListItemButton
                            selected={selectedItem === "/accountlist"}
                            // onClick={(event) => handleMenuItemClick(31)}
                            sx={{ color: getItemColor("/accountlist"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/accountlist") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <Group sx={{ color: getIconColor("/accountlist") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.SUB_ACCOUNTS')} />
                        </ListItemButton>
                        // </Link>
                    }
                    {(groupId === 0 || groupId === 1 || groupId === 10 || groupId === 11) &&
                        // <Link to="/userpricelist">
                        <ListItemButton
                            selected={selectedItem === "/userpricelist"}
                            // onClick={(event) => handleMenuItemClick(31)}
                            sx={{ color: getItemColor("/userpricelist"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/userpricelist") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <Group sx={{ color: getIconColor("/userpricelist") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.USER_PRICES')} />
                        </ListItemButton>
                        // </Link>
                    }

                    {/* 
                    <Link to="/topup">
                        <ListItemButton
                            selected={selectedItem === "/topup"}
                            // onClick={(event) => handleMenuItemClick(32)}
                            sx={{ color: getItemColor("/topup"), borderRadius: navItemBorderRadius }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <Paid sx={{ color: getIconColor("/topup") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.TOPUP')} />
                        </ListItemButton>
                    </Link> */}

                    {/* <Link to="/topuplog"> */}

                    <ListItemButton
                        selected={selectedItem === "/topuplog"}
                        // onClick={(event) => handleMenuItemClick(33)}
                        sx={{ color: getItemColor("/topuplog"), borderRadius: navItemBorderRadius }}
                        onClick={() => { navigate("/topuplog") }}
                    >
                        <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                            <ManageSearch sx={{ color: getIconColor("/topuplog") }} />
                        </ListItemIcon>
                        <ListItemText primary={t('SIDEBAR.TOPUP_LOG')} />
                    </ListItemButton>
                    {/* </Link> */}
                </List>
                {/* <Divider light /> */}
                <hr width="95%" boder="0" size="1" align="left" color="#ececec" />

                <List
                    dense={isDense}
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    subheader={<ListSubheader>{t('SIDEBAR.MY_ACCOUNT')} </ListSubheader>}
                >
                    {level < 2 &&
                        // <Link to="/authorizedcountries">
                        <ListItemButton
                            selected={selectedItem === "/authorizedcountries"}
                            // onClick={(event) => handleMenuItemClick(41)}
                            sx={{ color: getItemColor("/authorizedcountries"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/authorizedcountries") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <Public sx={{ color: getIconColor("/authorizedcountries") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.AUTHORIZED_COUNTRIES')} />
                        </ListItemButton>
                        // </Link> 
                    }
                    {groupId === 100 &&
                        // <Link to="/accountprofiles">
                        <ListItemButton
                            selected={selectedItem === "/accountprofiles"}
                            // onClick={(event) => handleMenuItemClick(41)}
                            sx={{ color: getItemColor("/accountprofiles"), borderRadius: navItemBorderRadius }}
                            onClick={() => { navigate("/accountprofiles") }}
                        >
                            <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                <Public sx={{ color: getIconColor("/accountprofiles") }} />
                            </ListItemIcon>
                            <ListItemText primary={t('SIDEBAR.ACCOUNT_PROFILES')} />
                        </ListItemButton>
                        // </Link>
                    }
                    {/* <Link to="/changepwd"> */}

                    <ListItemButton
                        selected={selectedItem === "/changepwd"}
                        // onClick={(event) => handleMenuItemClick(42)}
                        sx={{ color: getItemColor("/changepwd"), borderRadius: navItemBorderRadius }}
                        onClick={() => { navigate("/changepwd") }}
                    >
                        <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                            <Key sx={{ color: getIconColor("/changepwd") }} />
                        </ListItemIcon>
                        <ListItemText primary={t('SIDEBAR.CHANGE_PASSWORD')} />
                    </ListItemButton>
                    {/* </Link> */}
                </List>
            </Box>
        </Drawer>
    );
}


/*

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
        };
        const selectedItemIndex = useSelector((state) => state.customization.selectedItemIndex);
    }
    handleMenuItemClick = (idx) => {
        this.setState({ selectedIndex: idx });

        const dispatch = useDispatch();
        dispatch({ type: SET_MENU, selectedItemIndex: idx });
    };
    getItemColor(idx) {
        if (this.state.selectedIndex === idx) {
            return '#ef6c00';
            // return '#1976d2';
        } else {
            return '';
        }
    }

    getIconColor(idx) {
        if (this.state.selectedIndex === idx) {
            return '#ef6c00';
            // return '#1976d2';
        } else {
            // return 'green[500]';
            return '#4caf50';
        }

    }

    render() {
        const navItemBorderRadius = 1;
        const navItemMarginLeft = 1;
        const isDense = true;
    // alert(this.props.open);
        return (
            // <Box sx={{overflow: 'auto', bgcolor: '#ebf4d8'}}>

            <Drawer
                variant="persistent"
                anchor="left"
                open={this.props.open}
                sx={{
                    width: this.props.drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: this.props.drawerWidth, boxSizing: 'border-box' },
                }}
            // sx={{
            //   // display: { xs: 'block', lg: 'none' },
            //   // width: drawerWidth,
            //   '& .MuiDrawer-paper': {
            //       boxSizing: 'border-box',
            //       width: drawerWidth,
            //       flexShrink: 0,
            //       // borderRight: `1px solid ${theme.palette.divider}`,
            //       backgroundImage: 'none',
            //       boxShadow: 'inherit'
            //   }
            // }}
            >

                <Box sx={{ overflow: 'auto', padding: 1, mt: this.props.topbarHeight + 'px' }}>
                    <List
                        dense={isDense}
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>發送任務</ListSubheader>}
                    >
                        <Link to="/tasklist">
                            <ListItemButton
                                // selected={this.state.selectedIndex === 11}
                                selected={selectedItemIndex === 11}
                                onClick={() => this.handleMenuItemClick(1)}
                                sx={{ color: this.getItemColor(11), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <Assignment sx={{ color: this.getIconColor(11) }} />
                                </ListItemIcon>
                                <ListItemText primary="我的任務" />
                            </ListItemButton>
                        </Link>

                        <Link to="/mtlog">
                            <ListItemButton
                                // selected={this.state.selectedIndex === 12}
                                selected={selectedItemIndex === 12}
                                onClick={(event) => this.handleMenuItemClick(12)}
                                sx={{ color: this.getItemColor(12), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <ManageSearch sx={{ color: this.getIconColor(12) }} />
                                </ListItemIcon>
                                <ListItemText primary="記錄查詢" />
                            </ListItemButton>
                        </Link>

                        <Link to="/reporting">
                            <ListItemButton
                                selected={this.state.selectedIndex === 13}
                                onClick={(event) => this.handleMenuItemClick(13)}
                                sx={{ color: this.getItemColor(13), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <Summarize sx={{ color: this.getIconColor(13) }} />
                                </ListItemIcon>
                                <ListItemText primary="統計報表" />
                            </ListItemButton>
                        </Link>
                    </List>
                    <hr width="85%" boder="0" size="1" align="left" color="#ececec" />

                    <List
                        dense={isDense}
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>通訊錄 & 簽名檔</ListSubheader>}
                    >
                        <Link to="/addressgroup">
                            <ListItemButton
                                selected={this.state.selectedIndex === 20}
                                onClick={(event) => this.handleMenuItemClick(20)}
                                sx={{ color: this.getItemColor(20), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <Groups sx={{ color: this.getIconColor(20) }} />
                                </ListItemIcon>
                                <ListItemText primary="通訊錄分組" />
                            </ListItemButton>
                        </Link>

                        <Link to="/contacts">
                            <ListItemButton
                                selected={this.state.selectedIndex === 21}
                                onClick={(event) => this.handleMenuItemClick(21)}
                                sx={{ color: this.getItemColor(21), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <ContactPhone sx={{ color: this.getIconColor(21) }} />
                                </ListItemIcon>
                                <ListItemText primary="聯係人列表" />
                            </ListItemButton>
                        </Link>

                        <Link to="/signature">
                            <ListItemButton
                                selected={this.state.selectedIndex === 22}
                                onClick={(event) => this.handleMenuItemClick(22)}
                                sx={{ color: this.getItemColor(22), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <HistoryEdu sx={{ color: this.getIconColor(22) }} />
                                </ListItemIcon>
                                <ListItemText primary="簽名檔列表" />
                            </ListItemButton>
                        </Link>
                    </List>

                    <hr width="85%" boder="0" size="1" align="left" color="#ececec" />

                    <List
                        dense={isDense}
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>子帳號設定</ListSubheader>}
                    >
                        <Link to="/subaccounts">
                            <ListItemButton
                                selected={this.state.selectedIndex === 30}
                                onClick={(event) => this.handleMenuItemClick(30)}
                                sx={{ color: this.getItemColor(30), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <Group sx={{ color: this.getIconColor(30) }} />
                                </ListItemIcon>
                                <ListItemText primary="子帳號列表" />
                            </ListItemButton>
                        </Link>

                        <Link to="/topup">
                            <ListItemButton
                                selected={this.state.selectedIndex === 31}
                                onClick={(event) => this.handleMenuItemClick(31)}
                                sx={{ color: this.getItemColor(31), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <Paid sx={{ color: this.getIconColor(31) }} />
                                </ListItemIcon>
                                <ListItemText primary="子帳號充值" />
                            </ListItemButton>
                        </Link>

                        <Link to="/topuplog">

                            <ListItemButton
                                selected={this.state.selectedIndex === 32}
                                onClick={(event) => this.handleMenuItemClick(32)}
                                sx={{ color: this.getItemColor(32), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <ManageSearch sx={{ color: this.getIconColor(32) }} />
                                </ListItemIcon>
                                <ListItemText primary="充值記錄" />
                            </ListItemButton>
                        </Link>
                    </List>
                    <hr width="85%" boder="0" size="1" align="left" color="#ececec" />

                    <List
                        dense={isDense}
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>我的賬號</ListSubheader>}
                    >
                        <Link to="/authorizedcountries">
                            <ListItemButton
                                selected={this.state.selectedIndex === 40}
                                onClick={(event) => this.handleMenuItemClick(40)}
                                sx={{ color: this.getItemColor(40), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <Public sx={{ color: this.getIconColor(40) }} />
                                </ListItemIcon>
                                <ListItemText primary="已授權的國家" />
                            </ListItemButton>
                        </Link>
                        <Link to="/changepwd">

                            <ListItemButton
                                selected={this.state.selectedIndex === 41}
                                onClick={(event) => this.handleMenuItemClick(41)}
                                sx={{ color: this.getItemColor(41), borderRadius: navItemBorderRadius }}
                            >
                                <ListItemIcon sx={{ minWidth: 36, ml: navItemMarginLeft }}>
                                    <Key sx={{ color: this.getIconColor(41) }} />
                                </ListItemIcon>
                                <ListItemText primary="修改密碼" />
                            </ListItemButton>
                        </Link>
                    </List>
                </Box>
            </Drawer>
        );
    }
}
export default Sidebar;
*/