import { lazy } from 'react';
import MinimalLayout from '../layout/MinimalLayout';

// project imports
import Loadable from '../ui-component/Loadable';

// login option 3 routing
const SignIn = Loadable(lazy(() => import('../views/SignIn')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/signin',
            element: <SignIn />
        }
    ]

};

export default AuthenticationRoutes;
