import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
// import TaskList from '../views/Task/TaskList';
// import TaskNew from '../views/Task/TaskNew';
// import TaskEdit from '../views/Task/TaskEdit';

// // dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// // utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const TaskList = Loadable(lazy(() => import('../views/Task/TaskList')));
const TaskNew = Loadable(lazy(() => import('../views/Task/TaskNew')));
const SentLog = Loadable(lazy(() => import('../views/SentLog')));
const Reports = Loadable(lazy(() => import('../views/Reports')));
const AccountList = Loadable(lazy(() => import('../views/Account/AccountList')));
const AccountNew = Loadable(lazy(() => import('../views/Account/AccountNew')));
const TopupLog = Loadable(lazy(() => import('../views/TopupLog')));
const UserPriceList = Loadable(lazy(() => import('../views/Account/UserPriceList')));
const UserPriceNew = Loadable(lazy(() => import('../views/Account/UserPriceNew')));
const AddressGroupList = Loadable(lazy(() => import('../views/Contact/AddressGroupList')));
const AddressBookList = Loadable(lazy(() => import('../views/Contact/AddressBooktList')));
const AddressBookNew = Loadable(lazy(() => import('../views/Contact/AddressBookNew')));
const SignatureList = Loadable(lazy(() => import('../views/Signature/SignatureList')));
const SignatureNew= Loadable(lazy(() => import('../views/Signature/SignatureNew')));
const CountryList = Loadable(lazy(() => import('../views/Account/CountryList')));
const AccountProfiles = Loadable(lazy(() => import('../views/Account/AccountProfiles')));
const ChangePwd = Loadable(lazy(() => import('../views/Account/ChangePwd')));
const Welcome = Loadable(lazy(() => import('../views/Welcome')));
const SuspiciousMt = Loadable(lazy(() => import('../views/SuspiciousMt')));
const Words = Loadable(lazy(() => import('../views/Words')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Welcome />
        },
        {
            path: '/tasklist',
            element: <TaskList />
        },
        {
            path: '/tasknew',
            element: <TaskNew />
        },
        {
            path: '/sentlog',
            element: <SentLog />
        },
        {
            path: '/reports',
            element: <Reports />
        },
        {
            path: '/addressgrouplist',
            element: <AddressGroupList />
        },
        {
            path: '/addressbooklist',
            element: <AddressBookList />
        },
        {
            path: '/addressbooknew',
            element: <AddressBookNew />
        },
        {
            path: '/signaturelist',
            element: <SignatureList />
        },
        {
            path: '/signaturenew',
            element: <SignatureNew />
        },
        {
            path: '/accountlist',
            element: <AccountList />
        },
        {
            path: '/accountnew',
            element: <AccountNew />
        },
        {
            path: '/topuplog',
            element: <TopupLog />
        },
        {
            path: '/userpricelist',
            element: <UserPriceList />
        },
        {
            path: '/userpricenew',
            element: <UserPriceNew />
        },
        {
            path: '/authorizedcountries',
            element: <CountryList />
        },
        {
            path: '/accountprofiles',
            element: <AccountProfiles />
        },
        {
            path: '/changepwd',
            element: <ChangePwd />
        },
        {
            path: '/suspiciousmt',
            element: <SuspiciousMt />
        },
        {
            path: '/sensitivewords',
            element: <Words />
        },
        {
            path: '/whitelistwords',
            element: <Words />
        },

        // {
        //     path: '/dashboard/default',
        //     element: <DashboardDefault />
        // },
        // {
        //     path: '/utils/util-typography',
        //     element: <UtilsTypography />
        // },
        // {
        //     path: '/utils/util-color',
        //     element: <UtilsColor />
        // },
        // {
        //     path: '/utils/util-shadow',
        //     element: <UtilsShadow />
        // },
        // {
        //     path: '/icons/tabler-icons',
        //     element: <UtilsTablerIcons />
        // },
        // {
        //     path: '/icons/material-icons',
        //     element: <UtilsMaterialIcons />
        // },
        
    ]
};

export default MainRoutes;
