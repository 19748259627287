import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';
// import { useTranslation } from "react-i18next";
import i18n from "i18next";

// ----------------------------------------------------------------------
// const { t } = useTranslation();

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: './images/illustrations/ic_flag_en.svg',
  },
  {
    value: 'zh',
    label: '繁體中文',
    icon: './images/illustrations/ic_flag_tw.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (lng) => {
    setOpen(null);
    if(lng) i18n.changeLanguage(lng);
  };

  const getCurrentLANG = ()=>{

    for(let i=0; i<LANGS.length; i++){
      if(LANGS[i].value === i18n.resolvedLanguage) {
        // console.log('return ' + LANGS[i].value)
        return LANGS[i];
      }
    }
    // i18n.changeLanguage(LANGS[0].value);
    return LANGS[0];
  }
  
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 36,
          height: 36,
          mr: 1,
          // bgcolor: 'lightgrey',
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={getCurrentLANG().icon} alt={getCurrentLANG().label} width="24px" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => handleClose(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === i18n.resolvedLanguage} onClick={() => handleClose(option.value)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
