import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// project imports
import Topbar from './Topbar';
import Sidebar from './Sidebar';
// import Customization from '../Customization';

import { drawerWidth } from '../../store/constant';
import { SET_MENU } from '../../store/actions';

const mainPaddingLeft = 5;
const mainPaddingRight = 5;
const mainPaddingTop = 0;
const topbarMinHeight = 48;
const logoHeight = 55; //设置Topbar里面的logo图片的高度，此高度也将会影响 Topbar 的高度
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    // width: '100%', 
    // marginLeft: `-${drawerWidth}px`,
    paddingLeft: `${mainPaddingLeft}px`,
    paddingRight: `${mainPaddingRight}px`,
    paddingTop: `${(logoHeight < topbarMinHeight ? topbarMinHeight : logoHeight) + mainPaddingTop}px`,
    flexGrow: 1,
    // p: 0,
    backgroundColor: theme.palette.primary.background,
    minHeight: `calc(100vh)`,
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: `-${drawerWidth}px`,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            // marginLeft: '20px',
            marginLeft: `-${drawerWidth}px`,
            width: `calc(100% - ${drawerWidth}px)`,
            // padding: '16px'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: `-${drawerWidth}px`,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '10px',
            marginLeft: `-${drawerWidth}px`,
            width: `calc(100% - ${drawerWidth}px)`,
            // padding: '16px',
            // marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        // marginLeft: `-${mainPaddingLeft}px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: `-${mainPaddingLeft}px`
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: `-${mainPaddingLeft}px`
        // }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {

    // // drawer toggler
    // const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);
    // const handleDrawerToggle = () => {
    //     setLeftDrawerOpened(!leftDrawerOpened);
    //     // dispatch(openDrawer({ drawerOpen: !open }));
    // };
    // // const drawerWidth = 190;
    // const theme = useTheme();


    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    // lineHeight: 1,
                    bgcolor: theme.palette.background.default,
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
            >
                <Topbar handleLeftDrawerToggle={handleLeftDrawerToggle} logoHeight={logoHeight} />
            </AppBar>

            <Sidebar open={leftDrawerOpened} drawerWidth={drawerWidth} handleDrawerToggle={handleLeftDrawerToggle} topbarHeight={logoHeight < topbarMinHeight ? topbarMinHeight : logoHeight} />

            <Main theme={theme} open={leftDrawerOpened}>
                <Outlet />
            </Main>

        </Box>
    );
};

export default MainLayout;
