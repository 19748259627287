import './Topbar.css';
import * as React from 'react';
import { Menu } from '@mui/icons-material';
import { AppBar, Toolbar, Typography, IconButton, Stack } from '@mui/material';
import { goRelogin, showHttpError } from '../../../components/MyAlert';
import MyAxios from '../../../components/MyAxios';
import AccountMenu from './AccountMenu';
import { useTranslation } from 'react-i18next';
import LanguagePopover from '../../../ui-component/LanguagePopover';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getJwtPayload } from '../../../components/MyUtil';

export default function Topbar(props) {
  // const [userDesc, setUserDesc] = React.useState("");
  const [groupId, setGroupId] = React.useState(-1);
  const [userId, setUserId] = React.useState("");
  const [userCredit, setUserCredit] = React.useState(-1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const createLogoutButton = () => {
  //   let myToken = getJwtToken();
  //   if (myToken) {
  //     return <Tooltip title="LOGOUT">
  //       <IconButton
  //         size="large"
  //         aria-label="account of current user"
  //         aria-controls="menu-appbar"
  //         aria-haspopup="true"
  //         onClick={logout}
  //         color="inherit"
  //       >
  //         <Logout />
  //       </IconButton>
  //     </Tooltip>
  //   } else {
  //     window.location.href = "./SignIn";
  //   }
  // }

  const loadUserDesc = () => {
    // console.log("正在獲取用戶餘額信息。。。");
    MyAxios.get('/User')
      .then(function (response) {
        switch (response.data.code) {
          case 0:
            if (response.data.data.paidType === 0)
              // setUserDesc("( POSTPAID )");
              setUserCredit(-9);
            else
              // setUserDesc(`( ${t('COMMON.BALANCE')}: ${response.data.data.credit} )`)
              setUserCredit(response.data.data.credit);
            break;
          case 1010: //Record not found
          default:
            // setUserDesc("( ERROR )");
            setUserCredit(-999);
            break;
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          // window.location.href = "./SignIn";
          navigate("/SignIn");
        } else {
          // setUserDesc("(ERROR)");
          setUserCredit(-999);
          showHttpError(t, error, t('COMMON.MSG_FETCH_X').replace('[DATA_NAME]', t('ACCOUNT.ACCOUNT_INFO')));
        }
      });
  };
  const getUserCreditDesc = () => {
    switch (groupId) {
      case 0:
        return '( SA )';
      case 1:
        return '( Admin )';
      case 10:
        return '( Operation Manager )';
      case 11:
        return '( PIC )';
      default:
        switch (userCredit) {
          case -1:
            return '';
          case -9:
            return '( POSTPAID )';
          case -999:
            return '(ERROR)';
          default:
            return `( ${t('COMMON.BALANCE')}: ${userCredit} )`
        }
    }

  }
  const userCreditChanged = useSelector((state) => state.customization.userCreditChanged);

  React.useEffect(() => {
    //每次更新页面后会执行，且仅执行一次
    const jwtPayload = getJwtPayload();
    if (!jwtPayload) {
      // console.log('Topbar ==> jwtToken is null')
      goRelogin(t, 'Topbar ==> React.useEffect()', false);
    } else {
      setUserId(jwtPayload.name);
      setGroupId(Number(jwtPayload.role));
      if (Number(jwtPayload.role) === 100) {
        if (Number(jwtPayload.paidtype) === 0)
          setUserCredit(-9);
        else
          loadUserDesc();
      }
    }
  }, [userCreditChanged]);

  return (
    // <Box sx={{ flexGrow: 1 }}>
    <AppBar position="fixed">
      <Toolbar variant='dense'>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={props.handleLeftDrawerToggle}
          sx={{ mr: 0 }}
        >
          <Menu />
        </IconButton>
        <a href="./" className='line-height-1'><img src="./images/logo.png" height={props.logoHeight + 'px'} alt="" /></a>


        <Typography variant="button" component="div" sx={{ flexGrow: 1, textAlign: 'right', textTransform: 'none' }}>
          <LanguagePopover />
          {/* {userId}&nbsp;{getUserCreditDesc()} */}
        </Typography>
        {/* <table className='table-userinfo'>
          <tr><td className='table-userid'>{userId}</td></tr>
          <tr><td className='table-balance'>{getUserCreditDesc()}</td></tr>
        </table> */}
        <Stack direction='column' justifyContent="center" alignItems="center">
          <Typography variant='body2'>{userId}</Typography>
          <Typography variant='body2' sx={{ lineHeight: 1 }}>{getUserCreditDesc()}</Typography>
        </Stack>

        {/* <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          onClick={handleMenu}
        >
          <AccountCircle />
        </IconButton> */}
        <AccountMenu />

        {/* <Tooltip title="LOGOUT">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={logout}
          >
            <Logout />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
    </AppBar>
    // </Box>
  );
}
