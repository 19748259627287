import _axios from 'axios';
import { getJwtToken } from './MyUtil';
import { g } from '../Config';


const MyAxios = () => {
    // let myToken = getJwtToken();
    // let access_token = "";
    // if (myToken) {
    //     access_token = myToken.access_token;
    // }

    let access_token = getJwtToken();
    // alert('MyAxios.js access_token=' + access_token);
    //建立一個自定義的axios
    const instance = _axios.create({
            // baseURL: global.constants.baseApiUrl, //JSON-Server端口位置
            baseURL: g.baseApiUrl, //JSON-Server端口位置
            headers: { 'Authorization': 'Bearer ' + access_token }
        });

     return instance;
}

export {MyAxios};
export default MyAxios();